import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ArrowBtn} from "../../app-constants";
import {Link} from "gatsby"
import axios from "axios";
import {baseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";
import {checkData} from "../../helper";
import Loader from "../components/Loader";


function Servicelisting(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [servicesData, setServicesData] = useState([]);
    const getGiHubServiceWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/pages-with-category`);
        setServicesData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubServiceWithAxios();
    }, []);

    const data = servicesData;

    return (
        <>
            {isLoaded ?
                <Loader/>
                :
                <section className="p-100 pt-0 ">
                    <Container>

                        {data?.map((value, i) => (
                            <>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}
                                         className="seriveces_listing page_listing">
                                        <div className="example" data-text={`${value.name}`}>
                                            <h2>{value.name} Services</h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    {value?.services?.data?.map((item, i) => (
                                        <Col xs={12} md={6} lg={4} xl={4} xxl={4} key={i}>
                                            <div className="item_listing">
                                                <h4>{item.name}</h4>
                                                {HtmlParser(checkData(item, 'menu_txt'))}
                                                <Link to={`/services/${item.slug}`}>
                                                    {ArrowBtn}
                                                </Link>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        ))}
                    </Container>
                </section>
            }
        </>
    );
}

export default Servicelisting;